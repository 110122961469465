import "./datatable.scss";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../driversdatatablesource";
import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc, onSnapshot, snapshotEqual } from "firebase/firestore";
import {db} from '../../firebase';
import { CSVLink, CSVDownload } from "react-csv";

const DriversDatatable = () => {

    const [data,setData] = useState([]);

    useEffect(()=> {
        // LISTEN (REALTIME)
        const unsub = onSnapshot(collection(db, "drivers"), (snapShot) => {
            // console.log("Current data: ", doc.data());
            let list = [];

            snapShot.docs.forEach(doc=>{
                list.push({id:doc.id, ...doc.data()});
            });
            setData(list);
        },
        (error) => {
            // console.log(error);
        }
        );
        return () => {unsub();};
    },[]);

    // console.log(data);

    const actionColumn = [
        {filed: "action", headerName: "Action", width: 100, renderCell:(params)=>{
            return (
                <div className="cellAction">
                    <Link to={`/drivers/${params.row.id}`} style={{textDecoration: "none"}}>
                        <div className="viewButton">View</div>
                    </Link>
                    {/* <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div> */}
                </div>
            )
        }}
    ];

    let headers;

    headers = [
        { label: "First Name", key: "driverfirstName" },
        { label: "Last Name", key: "driverlastName" },
        { label: "Gender", key: "driverGender" },
        { label: "National ID", key: "nationalID" },
        { label: "Year Of Birth", key: "driverYearOfBirth" },
        { label: "Phone Number", key: "driverPhone" },
        { label: "Driver Type", key: "driverType" },
        { label: "Drivers License", key: "driverLicense" },
        { label: "County", key: "county" },
        { label: "SubCounty", key: "subCounty" },
        { label: "Ward", key: "ward" },
        { label: "Stage", key: "stage" },
        { label: "Sacco/Cooperative", key: "groupname" },
        { label: "Tuk Tuk Number Plate", key: "tuktukPlateNo" },
        { label: "Tuk Tuk Owner", key: "tuktukOwner" },
        { label: "Tuk Tuk Owner ID", key: "tuktukOwnerID" },
        // { label: "First Name", key: "driverfirstName" }
    ];

    const handleRowClick = (e) => {

    }
  return (
    <div className="datatable">
        <div className="datatableTitle">
            {/* Add New User
            <Link to="/users/new" style={{textDecoration: "none"}} className="link">
                Add New
            </Link> */}
            {/* <Link to="/users/new" style={{textDecoration: "none"}} className="link">
                Export
            </Link> */}
            {/* <CSVDownload data={data} target="_blank" />; */}
            <p>Drivers</p>
            {/* <CSVLink data={data} filename={"tuktukdrivers.csv"} headers={headers} className="link text-black" target="_blank">EXPORT</CSVLink> */}
        </div>
        <DataGrid
        className="datagrid"
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
            // components={{ Toolbar: GridToolbar }}
            onRowClick={handleRowClick}
        />
    </div>
  )
}

export default DriversDatatable