import React, { useEffect, useState } from 'react'
import "./widget.scss"
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { collection, query, where, getDoc, getDocs } from 'firebase/firestore';
import {db} from "../../firebase";

const StatWidget = ({type}) => {

  const [total, setTotal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [diff, setDiff] = useState(null);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const [license, setLicense] = useState(null);

  let data;

  // temporary
  // const amount = 100;
  // const diff = 20;

  switch (type) {
    case "total":
      data = {
        title:"Total Drivers",
        isMoney: false,
        isMale: false,
        isTotal: true,
        isFemale: false,
        isLicense: false,
        link:"See all drivers",
        query: "drivers",
        icon: <PersonOutlineOutlinedIcon className='icon' style={{color: "crimson", backgroundColor: "rgba(255, 0, 0, 0.2),"}}/>,
      };
      break;
      case "male":
      data = {
        title:"Male Drivers",
        isMoney: false,
        isMale: true,
        isTotal: false,
        isFemale: false,
        isLicense: false,
        link:"View all drivers",
        query: "drivers",
        icon: <ManIcon className='icon' style={{color: "goldenrod", backgroundColor: "rgba(218, 165, 32, 0.2),"}}/>,
      };
      break;
      case "female":
      data = {
        title:"Female Drivers",
        isMoney: false,
        isTotal: false,
        isMale: false,
        isFemale: true,
        isLicense: false,
        query: "drivers",
        link:"View all drivers",
        icon: <WomanIcon className='icon' style={{color: "green", backgroundColor: "rgba(0, 128, 0, 0.2),"}}/>,
      };
      break;
      case "license":
      data = {
        title:"Driver's License",
        query: "drivers",
        isMoney: false,
        isTotal: false,
        isMale: false,
        isFemale: false,
        isLicense: true,
        link:"See details",
        icon: <LocalPoliceIcon className='icon' style={{color: "purple", backgroundColor: "rgba(128, 0, 128, 0.2),"}}/>,
      };
      break;
  
    default:
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      const today = new Date();
      const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
      const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

      // console.log(prevMonth);
      // data.query = "users"
      const lastMonthQuery = query(collection(db, data.query), where("timeStamp", "<=", today), where("timeStamp", ">", lastMonth));
      const prevMonthQuery = query(collection(db, data.query), where("timeStamp", "<=", lastMonth), where("timeStamp", "<", prevMonth));

      const lastMonthData = await getDocs(lastMonthQuery);
      const prevMonthData = await getDocs(prevMonthQuery);

      setAmount(lastMonthData.docs.length);

      // total drivers
      const totaldr = query(collection(db,data.query));
      const totals = await getDocs(totaldr);
      setTotal(totals.docs.length)
      
      // Total Male Drivers
      const maletotal = query(collection(db, data.query), where("driverGender", "==", "Male"));
      const maleTotals = await getDocs(maletotal);
      setMale(maleTotals.docs.length);


      // Total Female Drivers
      const femaletotal = query(collection(db, data.query), where("driverGender", "==", "Female"));
      const femaleTotals = await getDocs(femaletotal);
      setFemale(femaleTotals.docs.length);

      // Total Licensed Drivers
    //   const female = "Female";
      const licensetotal = query(collection(db, data.query), where("driverLicense", "==", "Yes"));
      const licenseTotals = await getDocs(licensetotal);
      setLicense(licenseTotals.docs.length);


      // setDiff(((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) * 100 );
      // setDiff(
      //   ((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) * 100);

      setDiff(((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) * 100);
    }
    fetchData();
  },[])


  return (
    <div className='w-full'>
    <div className='widget w-60 h-36'> 
    {/* w-60 h-32 */}
      <div className="left">
        <span className='title'>{data.title}</span>
        <span className='counter'>{data.isMoney && "$"} {data.isTotal && `${total}`} {data.isMale && `${male}`} {data.isFemale && `${female}`} {data.isLicense && `${license}`}</span>
        <span className='link'>{data.link}</span>
      </div>
      <div className="right">
        <div className={`percentage ${diff < 0 ? "negative" : "positive"}`}>
          {diff < 0 ? <KeyboardArrowDownOutlinedIcon/> : <KeyboardArrowUpOutlinedIcon/>}
          {Number(diff).toFixed(2)} %</div>
          {data.icon}
      </div>
    </div>
    </div>
  )
}

export default StatWidget