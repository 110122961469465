import React from 'react';
import AppBar from '../../components/AppBar/AppBar';
import NavBar from '../../components/navbar/Navbar';
import StatWidget from '../../components/widget/StatWidget';
import "./statistics.scss"


const Statistics = () => {

  const county = [
    'Mombasa',
    'Kwale',
    'Kilifi',
    'Tana River',
    'Lamu',
    'Taita Taveta',
    'Garissa',
    'Wajir',
    'Mandera',
    'Marsabit',
    'Isiolo',
    'Meru',
    'Tharaka-Nithi',
    'Embu',
    'Kitui',
    'Machakos',
    'Makueni',
    'Nyandarua',
    'Nyeri',
    'Kirinyaga',
    'Murang\'a',
    'Kiambu',
    'Turkana',
    'West Pokot',
    'Samburu',
    'Trans Nzoia',
    'Uasin Gishu',
    'Elgeyo Marakwet',
    'Nandi',
    'Baringo',
    'Laikipia',
    'Nakuru',
    'Narok',
    'Kajiado',
    'Kericho',
    'Bomet',
    'Kakamega',
    'Vihiga',
    'Bungoma',
    'Busia',
    'Siaya',
    'Kisumu',
    'Homabay',
    'Migori',
    'Kisii',
    'Nyamira',
    'Nairobi City',
  ];

  return (
    <div className='flex'>
        <AppBar/>
        <NavBar/>
        <div className='mt-12 ml-8'>
            <div className='grid grid-cols-1 sm:grid-cols-4 widgets lg:ml-60 mt-10'>
              <StatWidget type="total"/>
              <StatWidget type="male"/>
              <StatWidget type="female"/>
              <StatWidget type="license"/>
            </div>
            <div className='lg:ml-60 mt-4'>
              <h3>Statistics per county</h3>
            </div>
        </div>
    </div>
  )
}

export default Statistics