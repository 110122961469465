import Home from "./pages/home/Home";
// import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from "./pages/login/Login";
import DriversList from "./pages/list/DriversList";
import New from "./pages/new/New";
import Single from "./pages/single/Single";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import UsersList from "./pages/list/UsersList";
import Statistics from "./pages/statistics/Statistics";
import { Profile } from "./pages/profile/Profile";
import CountyAdmins from "./pages/admins/Admins";
import SingleAdmin from "./pages/single/SingleAdmin";
import NewCountyAdmin from "./pages/new/NewCountyAdmin";
import CountyAdminHome from "./countyadmins/pages/CountyAdminHome";
import CountyDriversList from "./countyadmins/pages/list/CountyDriversList";

function App() {

  const {darkMode} = useContext(DarkModeContext);

  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/"/>;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
     <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route index element={<Login />}/>
        <Route path="/home" element={<RequireAuth><Home /></RequireAuth>}/>
        {/* <Route path="users">
          <Route index element={<RequireAuth><UsersList/></RequireAuth>}/>
          <Route path=":userId" element={<RequireAuth><Single/></RequireAuth>}/>
          <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New User"/></RequireAuth>}/>
        </Route> */}
        <Route path="drivers">
          <Route index element={<RequireAuth><DriversList/></RequireAuth>}/>
          <Route path=":driverId" element={<RequireAuth><Single/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New Driver"/></RequireAuth>}/> */}
        </Route>
        {/* <Route path="countyadmins">
          <Route index element={<RequireAuth><CountyAdmins/></RequireAuth>}/>
          <Route path=":adminId" element={<RequireAuth><SingleAdmin/></RequireAuth>}/>
          <Route path="new" element={<RequireAuth><NewCountyAdmin inputs={userInputs} title="Add New Admin"/></RequireAuth>}/>
        </Route> */}
        {/* <Route path="products">
          <Route index element={<RequireAuth><DriversList/></RequireAuth>}/>
          <Route path=":productId" element={<RequireAuth><Single/></RequireAuth>}/>
          <Route path="new" element={<RequireAuth><New inputs={productInputs} title="Add New Product"/></RequireAuth>}/>
        </Route> */}
        <Route path="statistics">
          <Route index element={<RequireAuth><Statistics/></RequireAuth>}/>
        </Route>
        <Route path="profile">
          <Route index element={<RequireAuth><Profile/></RequireAuth>}/>
        </Route>
        {/* <Route path="countyadmindash">
          <Route index element={<RequireAuth><CountyAdminHome/></RequireAuth>}/>
          <Route path="drivers-county">
            <Route index element={<RequireAuth><CountyDriversList/></RequireAuth>}/>
            <Route path=":driverId" element={<RequireAuth><Single/></RequireAuth>}/>
          </Route>
        </Route> */}
      </Route>
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
