import "./list.scss"
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DriversDatatable from "../../components/datatable/DriversDatatable";
import NavBar from '../../components/navbar/Navbar'
import AppBar from '../../components/AppBar/AppBar'

const DriversList = () => {
  return (
    <div className="flex">
      <AppBar/>
      <NavBar/>
      <div className="w-full list mt-12 ml-2">
        <div className="lg:ml-60 mt-10">
          <DriversDatatable/>
        </div>
      </div>
    </div>
  )
}

export default DriversList