import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react'
import "./profile.scss";
import AppBar from '../../components/AppBar/AppBar'
import NavBar from '../../components/navbar/Navbar'
import { AuthContext } from '../../context/AuthContext';
import { collection, doc, onSnapshot, getDoc } from "firebase/firestore";
import {db} from '../../firebase';
import { updateEmail, updatePassword } from 'firebase/auth';

export const Profile = () => {
    const [file, setFile] = useState(null);

    const [phone, setPhone] = useState("");

    const [firstName, setFName] = useState("");

    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");

    const [success, setSuccess] = useState(false);

    const {currentUser} = useContext(AuthContext);

    const [data,setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          // let list = [];
          try {
            const docRef = doc(db, "admins", currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              // console.log("Document data:", docSnap.data());
              setData(docSnap.data());
            } else {
              // doc.data() will be undefined in this case
              // console.log("No such document!");
            }
            // console.log(list);
          } catch (err) {
            // console.log(err);
          }
        };
        fetchData();
      });

      const handleSubmit = async (e) => {
        e.preventDefault();
        if (email !== "") {
            updateEmail(currentUser, email).then(() => {
                setSuccess(true);
            }).catch(err => {
                // console.log(err);
            })
        }
        if (password !== "") {
            updatePassword(currentUser, password).then(() => {
                setSuccess(true);
            }).catch(err => {
                // console.log(err);
            });
        }
      }

  return (
    <div className='flex'>
        <AppBar/>
        <NavBar/>
        <div className='w-full p-5 list mt-20 ml-5 profileForm'>
            <div className='lg:ml-60 mt-20 mr-6'>
            <span className="text-lg mb-2">Update Your Account</span>
                <form className='flex flex-col' onSubmit={handleSubmit}>
                    <div className='flex align-center settingsPP'>
                        <img src={file ? URL.createObjectURL(file) : data.image} alt="profile" />
                        <label htmlFor="fileInput">
                            <i className="settingsPPIcon fa-solid fa-user"></i>
                        </label>
                        <input type="file" id="fileInput" style={{display:"none"}} onChange={(e) => setFile(e.target.files[0])}/>
                    </div>
                    <label>First Name</label>
                    <input type="text" placeholder={data.firstName} onChange={(e)=>setFName(e.target.value)}/>
                    <label>Email</label>
                    <input type="email" placeholder={data.email} onChange={(e)=>setEmail(e.target.value)}/>
                    <label>Phone Number</label>
                    <input type="text" placeholder={data.phoneNumber} onChange={(e)=>setPhone(e.target.value)}/>
                    <label>Password</label>
                    <input type="password" onChange={(e)=>setPassword(e.target.value)}/>
                    <button className="w-60 text-sm text-white p-2 m-3 rounded-xl mainbgcolor" type="submit">Update</button>
                    {success && <span className="settingsSuccess">Account Updated</span>}
                </form>
            </div>
        </div>
    </div>
  )
}
